import React, { useEffect, useState } from "react";
import { trendsApi } from "src/api";
import { PersonalRecordTrends } from "src/interfaces/trends.interface";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import LoadingTable from "../loading/LoadingTable";
import parseMonthDayYear from "src/helpers/parseMonthDayYear";
import { useNavigate } from "react-router-dom";
import { Option } from "../fields/SelectInput";
import { capitalize } from "src/helpers/capitalize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToolbarItem } from "./TrendsToolbar";
import useUserContext from "src/hooks/private/useUserContext";
import { metricConversionMap } from "src/utils/conversionFunctions";

export type RecordType = "power" | "speed" | "strength" | "acceleration" | "shredmill";

export type TimeFrames = "day" | "week" | "month" | "year";

type SortColumn = "athlete_name" | "personal_record" | "date";
type SortOrder = "asc" | "desc";

type CaretIconOptions = "up" | "down";

interface CaretIconProps {
  input: CaretIconOptions;
}

const CaretIcon: React.FC<CaretIconProps> = ({ input }) => {
  return (
    <FontAwesomeIcon
      className="absolute ml-1 mt-0.5 text-lg"
      icon={input === "up" ? faCaretUp : faCaretDown}
    />
  );
};

const PersonalRecords = () => {
  const [type, setType] = useState<RecordType>("power");
  const { gymMetricPreferences, metricTypes, gym } = useUserContext();
  const [timeFrame, setTimeFrame] = useState<TimeFrames>("month");
  const [isLoadingPRs, setIsLoadingPRs] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<SortColumn>("date");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const navigate = useNavigate();
  const [personalRecords, setPersonalRecords] = useState<
    PersonalRecordTrends[]
  >([]);

  const calculateStartDate = (timeFrame: TimeFrames): Date => {
    const date = new Date();
    switch (timeFrame) {
      case "day":
        date.setDate(date.getDate() - 1);
        break;
      case "week":
        date.setDate(date.getDate() - 7);
        break;
      case "month":
        date.setMonth(date.getMonth() - 1);
        break;
      case "year":
        date.setFullYear(date.getFullYear() - 1);
        break;
    }
    return date;
  };

  const fetchPersonalRecords = async (startDate: Date) => {
    try {
      setIsLoadingPRs(true);
      const res = await trendsApi.getPersonalRecords(startDate);
      setPersonalRecords(res);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingPRs(false);
    }
  };

  useEffect(() => {
    const startDate = calculateStartDate(timeFrame);
    fetchPersonalRecords(startDate);
  }, [timeFrame]);

  const getUnitForType = (recordType: RecordType): string => {
    const exerciseId = {
      power: 1,
      strength: 2,
      acceleration: 3,
      speed: 4,
      shredmill: 5,
    }[recordType];

    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === exerciseId
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    return metricType?.unit || "";
  };

  const convertMetric = (
    pr: PersonalRecordTrends
  ): { value: number; unit: string } => {
    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === pr.exercise_id
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    if (metricType) {
      // Check if there's a conversion function for the given metric_type_id
      const conversion = metricConversionMap[metricType.id];
      if (conversion) {
        return {
          value: Number(conversion.fromMPH(pr.personal_record).toFixed(2)),
          unit: metricType.unit,
        };
      }
    }

    return { value: pr.personal_record, unit: metricType?.unit || "" };
  };

  const filteredRecords = personalRecords.filter((pr: PersonalRecordTrends) => {
    switch (type) {
      case "power":
        return pr.exercise_id === 1;
      case "strength":
        return pr.exercise_id === 2;
      case "acceleration":
        return pr.exercise_id === 3;
      case "speed":
        return pr.exercise_id === 4;
      case "shredmill":
        return pr.exercise_id === 5;
    }
    return true;
  });

  const sortedRecords = [...filteredRecords].sort((a, b) => {
    if (sortColumn === "date") {
      return sortOrder === "asc"
        ? new Date(a.date).getTime() - new Date(b.date).getTime()
        : new Date(b.date).getTime() - new Date(a.date).getTime();
    } else if (sortColumn === "athlete_name") {
      return sortOrder === "asc"
        ? a.athlete_name.localeCompare(b.athlete_name)
        : b.athlete_name.localeCompare(a.athlete_name);
    } else if (sortColumn === "personal_record") {
      return sortOrder === "asc"
        ? a.personal_record - b.personal_record
        : b.personal_record - a.personal_record;
    }
    return 0;
  });

  const handleAthleteClick = (athleteID: number) => {
    navigate(`/athletes/${athleteID}`);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeFrame(event.target.value as TimeFrames);
  };

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const options: Option[] = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ];

  const activeMetrics = [
    gym?.is_power && "power",
    gym?.is_strength && "strength",
    gym?.is_acceleration && "acceleration",
    gym?.is_speed && "speed",
    gym?.is_shredmill && "shredmill",
  ].filter(Boolean) as RecordType[];

  const isActiveLinks = activeMetrics.reduce((acc, metric) => {
    acc[metric] = metric === type;
    return acc;
  }, {} as { [key: string]: boolean });

  const handleActive = (metric: RecordType) => {
    setType(metric);
  };

  return (
    <>
      <div className="max-w-screen-md mx-auto">
        <div className="mb-1 flex items-center justify-between w-full">
          <h1 className="text-xxl font-bold text-primary">Personal Records</h1>
          <select
            className="select select-bordered max-w-xs py-0"
            onChange={handleSelectChange}
            defaultValue={"month"}
          >
            {options.map((opt: Option) => (
              <option key={`op-${opt.value}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-1 flex items-center justify-center w-full">
          <div className="tabs tabs-boxed w-fit">
            {activeMetrics.map((metric) => (
              <ToolbarItem
                key={metric}
                title={
                  metric === "acceleration" ? "Agility" : capitalize(metric)
                }
                isActive={isActiveLinks[metric]}
                onClick={() => handleActive(metric)}
              />
            ))}
          </div>
        </div>

        <div>
          {isLoadingPRs ? (
            <LoadingTable label={"PRs"} />
          ) : (
            <table className="table relative overscroll-y-none table-fixed table-compact w-full">
              <thead className="sticky top-0">
                <tr className="border-b-2 bg-light-focus sticky text-primary ">
                  <th
                    className="bg-light-focus text-left cursor-pointer items-center select-none"
                    onClick={() => handleSort("athlete_name")}
                  >
                    Athlete
                    {sortColumn === "athlete_name" && (
                      <CaretIcon input={sortOrder === "asc" ? "up" : "down"} />
                    )}
                  </th>
                  <th
                    className="bg-light-focus text-center cursor-pointer select-none"
                    onClick={() => handleSort("personal_record")}
                  >
                    PR
                    {` (${getUnitForType(type)})`}
                    {sortColumn === "personal_record" && (
                      <CaretIcon input={sortOrder === "asc" ? "up" : "down"} />
                    )}
                  </th>
                  <th
                    className="bg-light-focus text-center cursor-pointer select-none"
                    onClick={() => handleSort("date")}
                  >
                    Date
                    {sortColumn === "date" && (
                      <CaretIcon input={sortOrder === "asc" ? "up" : "down"} />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className="border-b-2">
                {sortedRecords.length ? (
                  sortedRecords.map(
                    (pr: PersonalRecordTrends, index: number) => {
                      const convertedMetric = convertMetric(pr);
                      return (
                        <tr
                          key={`prs-${type}-${index}`}
                          onClick={() => handleAthleteClick(pr.athlete_id)}
                          className="cursor-pointer"
                        >
                          <td className="text-left">{pr.athlete_name}</td>
                          <td className="text-center font-medium">
                            {convertedMetric.value} {convertedMetric.unit}
                          </td>
                          <td className="text-center">
                            {parseMonthDayYear(pr.date, 0, true)}
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td className="text-center" colSpan={3}>
                      {`No ${
                        type === "acceleration" ? "Agility" : capitalize(type)
                      } PRs for this time period`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalRecords;
