import React from "react";
import { ChartData } from "chart.js";
import logo from "src/assets/GenesisLogo_BlackShadow.png";
import { TimeFrames } from "./Progress";
import capitalize from "src/helpers/capitalize";
import GymPDFChart from "./GymPDFChart";

interface PDFOutputHTMLProps {
  chartData: ChartData<"bar", { x: Date | string; y: number }[], unknown>;
  title: string;
  unit: string;
  timeUnit: "day" | "week" | "month" | "year";
  timeFrame: TimeFrames
}

const GymPDFOutputHTML = ({
chartData,
title,
timeUnit,
timeFrame,
}: PDFOutputHTMLProps) => {
  return (
    <>
      <div
        id="pdf"
        className="flex items-center justify-between bg-white z-10 top-42 flex-col w-full relative"
      >
        <div className="absolute top-0 left-0 flex items-start justify-start w-full gap-4">
          <img
            src={logo}
            alt="Genesis Sports Performance"
            className="w-[35px]"
          />

          <h1 className="absolute -top-[12px] left-[40px] text-[26px] font-gta text-[#050707]">
            Genesis Sports Performance
          </h1>
        </div>
        <div className="flex items-center justify-center mt-12 mb-8 bg-back-light w-full px-4">
          {capitalize(timeFrame)}
        </div>
        <div className="w-[1000px] gap-4">
          <div className="col-span-1 row-span-1">
            {chartData && (
              <GymPDFChart
                chartData={chartData}
                title={title}
                unit={timeUnit}
                invertChart={false}
                timeUnit={
                  timeFrame === "week" || timeFrame === "month"
                    ? "day"
                    : "month"
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GymPDFOutputHTML;
