import gspLogo from "src/assets/GenesisLogo_BlackShadow.png";
import pptLogo from "src/assets/PPT.png";
import jungleLogo from "src/assets/jungle.png";
import battiLogo from "src/assets/batti.jpg";
import saintsLogo from "src/assets/saints.png";
import xplosiveLogo from "src/assets/xplosive.jpg";
import wisconsonLogo from "src/assets/wisconsin.jpg";
import dspLogo from "src/assets/dsp.png";
import ctspLogo from "src/assets/ctsp.jpg";
import { Link } from "react-router-dom";
import useViewport from "src/hooks/useViewport";
import useUserContext from "src/hooks/private/useUserContext";
import LoadingSpinner from "../loading/LoadingSpinner";

const HeaderNav = () => {
  const { width } = useViewport();

  const { isLoadingUser, user } = useUserContext();

  return (
    <header className="fixed top-0 right-0 left-0 bg-back-light z-50">
      <div className="flex flex-col columns-1 w-full">
        {isLoadingUser ? (
          <div className="flex items-center justify-center h-[75px]">
            <LoadingSpinner />
          </div>
        ) : (
          <Link to="/athletes">
            <div className="flex items-center justify-center gap-4">
              <div className="flex items-center">
                {user?.gym_id === 5 ? (
                  <img
                    src={pptLogo}
                    alt="Premier Performance Training"
                    className="w-[275px] py-0"
                  />
                ) : user?.gym_id === 6 ? (
                  <img
                    src={jungleLogo}
                    alt="Jungle Athletics"
                    className="w-[300px]"
                  />
                ) : user?.gym_id === 7 ? (
                  <div className="py-4">
                    <img
                      src={battiLogo}
                      alt="Batti Gym"
                      className="w-[290px]"
                    />
                  </div>
                ) : user?.gym_id === 8 ? (
                  <div className="py-4">
                    <img
                      src={saintsLogo}
                      alt="Iron Saints Athletic Systems"
                      className="w-[65px]"
                    />
                  </div>
                ) : user?.gym_id === 11 ? (
                  <div className="py-4">
                    <img
                      src={xplosiveLogo}
                      alt="Iron Saints Athletic Systems"
                      className="w-[65px]"
                    />
                  </div>
                ) : user?.gym_id === 10 ? (
                  <div className="py-4">
                    <img
                      src={wisconsonLogo}
                      alt="Wisconsin Sports Performance"
                      className="w-[120px]"
                    />
                  </div>
                ) : user?.gym_id === 12 ? (
                  <div className="py-4">
                    <img
                      src={dspLogo}
                      alt="DSP Training"
                      className="w-[120px]"
                    />
                  </div>
                ) : user?.gym_id === 13 ? (
                  <div className="py-4">
                    <img
                      src={ctspLogo}
                      alt="CTSP Training"
                      className="w-[220px]"
                    />
                  </div>
                ) : (
                  <img
                    src={gspLogo}
                    alt="Genesis Sports Performance"
                    className="w-[75px]"
                  />
                )}
              </div>
              {width > 690 && (
                <div>
                  {user?.gym_id === 6 ||
                  user?.gym_id === 5 ||
                  user?.gym_id === 11 ||
                  user?.gym_id === 10 ||
                  user?.gym_id === 7 ||
                  user?.gym_id === 13 ||
                  user?.gym_id === 12 ? (
                    <h1 className="text-[50px] font-gta text-[#050707]">
                      {/* Premier Performance Training */}
                    </h1>
                  ) : user?.gym_id === 8 ? (
                    <h1 className="text-[50px] font-gta text-[#050707]">
                      Iron Saints Athletic Systems
                    </h1>
                  ) : (
                    <h1 className="text-[50px] font-gta text-[#050707]">
                      Genesis Sports Performance
                    </h1>
                  )}
                </div>
              )}
            </div>
            <div className="w-full h-0.5 bg-primary"></div>
          </Link>
        )}
      </div>
    </header>
  );
};

export default HeaderNav;
