import React, { useState, useEffect } from "react";
import GymChart from "./GymChart";
import Metric from "src/interfaces/metric.interface";
import { trendsApi } from "src/api";
import { Option } from "../fields/SelectInput";
import LoadingTable from "../loading/LoadingTable";
import { ChartData } from "chart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import html2pdf from "html2pdf.js";
import GymPDFOutputHTML from "./GymPDFOutputHTML";
import useUserContext from "src/hooks/private/useUserContext";
import { metricConversionMap } from "src/utils/conversionFunctions";

export type TimeFrames = "week" | "month" | "6 months" | "year";

export type TimeUnit = "day" | "month";

const Progress = () => {
  const { gym, gymMetricPreferences, metricTypes } = useUserContext();
  const [progressData, setProgressData] = useState<Metric[]>([]);
  const [isLoadingProgressData, setIsLoadingProgressData] =
    useState<boolean>(false);
  const [timeFrame, setTimeFrame] = useState<TimeFrames>("6 months");
  const [showPDFContent, setShowPDFContent] = useState(false);

  const calculateStartDate = (timeFrame: TimeFrames): Date => {
    const date = new Date();
    switch (timeFrame) {
      case "week":
        date.setDate(date.getDate() - 7);
        break;
      case "month":
        date.setMonth(date.getMonth() - 1);
        break;
      case "6 months":
        date.setMonth(date.getMonth() - 6);
        break;
      case "year":
        date.setFullYear(date.getFullYear() - 1);
        break;
    }
    return date;
  };

  const fetchProgressData = async (startDate: Date) => {
    try {
      setIsLoadingProgressData(true);
      const res = await trendsApi.getProgressData(startDate);
      setProgressData(res);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingProgressData(false);
    }
  };

  useEffect(() => {
    const startDate = calculateStartDate(timeFrame);
    fetchProgressData(startDate);
  }, [timeFrame]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeFrame(event.target.value as TimeFrames);
  };

  const options: Option[] = [
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
    { label: "6 Months", value: "6 months" },
    { label: "Year", value: "year" },
  ];

  const convertMetricValue = (metric: Metric): number => {
    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === metric.exercise_id
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    if (metricType) {
      // Check if there's a conversion function for the given metric_type_id
      const conversion = metricConversionMap[metricType.id];
      if (conversion) {
        return Number(conversion.fromMPH(metric.value).toFixed(2));
      }

      // Fallback to using conversion_factor if available
      if (metricType.conversion_factor !== 1) {
        return Number((metricType.conversion_factor / metric.value).toFixed(2));
      }
    }

    // Return the original value if no conversion is needed or found
    return metric.value;
  };

  const aggregateData = (
    filteredMetrics: Metric[],
    timeUnit: "day" | "week" | "month" | "year"
  ) => {
    const dateToValuesMap: { [date: string]: number[] } = {};

    filteredMetrics.forEach((metric: Metric) => {
      const date = new Date(metric.date);
      let dateKey;
      switch (timeUnit) {
        case "day":
          dateKey = date.toISOString().split("T")[0];
          break;
        case "week":
          const weekStart = new Date(date);
          weekStart.setDate(date.getDate() - date.getDay()); // Get the start of the week
          dateKey = weekStart.toISOString().split("T")[0];
          break;
        case "month":
          dateKey = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}`;
          break;
        case "year":
          dateKey = date.getFullYear().toString();
          break;
      }
      if (!dateToValuesMap[dateKey]) {
        dateToValuesMap[dateKey] = [];
      }
      dateToValuesMap[dateKey].push(convertMetricValue(metric));
    });

    const averagedData = Object.keys(dateToValuesMap).map((dateKey) => {
      const values = dateToValuesMap[dateKey];
      const averageValue =
        values.reduce((acc, value) => acc + value, 0) / values.length;
      return {
        x: dateKey,
        y: Math.round(averageValue * 100) / 100,
      };
    });

    // Sort the data by date
    averagedData.sort(
      (a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()
    );

    return averagedData;
  };

  const convertMetricsToDataset = (
    metrics: Metric[],
    timeFrame: TimeFrames
  ): ChartData<"bar", { x: Date | string; y: number }[], unknown> => {
    const enabledMetrics = {
      power: gym?.is_power,
      strength: gym?.is_strength,
      acceleration: gym?.is_acceleration,
      speed: gym?.is_speed,
      shredmill: gym?.is_shredmill,
    };

    const metricMapping: { [key: string]: number } = {
      power: 1,
      strength: 2,
      acceleration: 3,
      speed: 4,
      shredmill: 5,
    };

    const labelMapping: { [key: string]: string } = {
      power: "Power",
      strength: "Strength",
      acceleration: "Agility",
      speed: "Speed",
      shredmill: "Shredmill",
    };

    const timeUnit =
      timeFrame === "week" || timeFrame === "month"
        ? "day"
        : timeFrame === "6 months"
        ? "week"
        : "month";

    const filteredMetrics = Object.keys(enabledMetrics)
      .filter((key) => enabledMetrics[key as keyof typeof enabledMetrics])
      .map((metric) => {
        const exerciseId = metricMapping[metric];
        return {
          label: labelMapping[metric],
          data: aggregateData(
            metrics.filter((m: Metric) => m.exercise_id === exerciseId),
            timeUnit
          ),
          backgroundColor:
            metric === "power"
              ? "rgba(22, 62, 99, 0.6)"
              : metric === "strength"
              ? "rgba(255, 99, 132, 0.6)"
              : metric === "acceleration"
              ? "rgba(54, 162, 235, 0.6)"
              : metric === "shredmill"
              ? "rgba(255, 165, 0, 0.6)"
              : "rgba(75, 192, 192, 0.6)",
          borderWidth: 1,
        };
      });

    return { datasets: filteredMetrics };
  };

  const handleExportToPDF = () => {
    setShowPDFContent(true);
  };

  useEffect(() => {
    if (showPDFContent) {
      const options = {
        margin: [10, 10, 10, 10] as [number, number, number, number],
        filename: `Progress_Chart.pdf`,
        image: { type: "jpeg" as "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
          willReadFrequently: true,
        },
        jsPDF: {
          unit: "mm" as "mm",
          format: "a4",
          orientation: "landscape" as "landscape",
        },
      };

      const timer = setTimeout(() => {
        const element = document.getElementById("pdf");
        if (element) {
          html2pdf()
            .from(element)
            .set(options)
            .output("blob")

            .then((blob) => {
              // Create a Blob URL for the PDF
              const blobUrl = URL.createObjectURL(blob);

              // Open the Blob URL in a new tab
              window.open(blobUrl, "_blank");

              // Hide the PDF content after generation
              setShowPDFContent(false);
            })
            .catch((error) => {
              console.error("Error generating PDF:", error);
              setShowPDFContent(false);
            });
        }
      }, 500);

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [showPDFContent]);

  const chartData = convertMetricsToDataset(progressData, timeFrame);

  return (
    <>
      <div className="w-full px-12 flex items-center justify-center flex-col">
        <div className="flex flex-row gap-2">
          <select
            className="select select-bordered max-w-xs py-0"
            onChange={handleSelectChange}
            defaultValue={"6 months"}
          >
            {options.map((opt: Option) => (
              <option key={`op-${opt.value}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
          <div>
            <button
              className="btn bg-white btn-outline border-gray-400"
              onClick={handleExportToPDF}
            >
              <FontAwesomeIcon icon={faFilePdf} className="text-lg" />
            </button>
          </div>
        </div>

        {isLoadingProgressData ? (
          <LoadingTable label={"Gym Progress"} />
        ) : (
          <div className="w-full mb-12">
            <div>
              <GymChart
                chartData={chartData}
                title={"Progress"}
                unit={""}
                timeUnit={
                  timeFrame === "week" || timeFrame === "month"
                    ? "day"
                    : "month"
                }
              />
            </div>
          </div>
        )}
        {showPDFContent && (
          <GymPDFOutputHTML
            chartData={chartData}
            title={"Progress"}
            unit={""}
            timeUnit={
              timeFrame === "week" || timeFrame === "month" ? "day" : "month"
            }
            timeFrame={timeFrame}
          />
        )}
      </div>
    </>
  );
};

export default Progress;
