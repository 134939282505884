import React, { useEffect, useRef, useState } from "react";
import Gym from "src/interfaces/gym.interface";
import FloatingInput from "../fields/FloatingInput";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import ModalButton from "../buttons/ModalButton";
import { UserRole } from "src/interfaces/user.interface";
import { userApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/private/useModal";
import SelectInput from "../fields/SelectInput";

interface AddUserModalProps {
  gym: Gym;
  fetchGyms: () => void;
  fetchTrainers: () => void;
}

const AddUserModal = ({ gym, fetchGyms, fetchTrainers }: AddUserModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  interface FormData {
    name: string;
    email: string;
    role: UserRole;
  }

  const initialFormData: FormData = {
    name: "",
    email: "",
    role: "trainer",
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  interface FormErrors {
    name: string;
    email: string;
  }

  const initialFormErrors: FormErrors = {
    name: "",
    email: "",
  };

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      role: value as UserRole, // Assuming UserRole is a string enum
    }));
  };

  const validate: any = (data: FormData) => {
    const errors: {
      name?: string;
      email?: string;
    } = {};
    if (!data.name.trim()) {
      errors.name = "Trainer name is required";
      return errors;
    }
    if (!data.email.trim()) {
      errors.email = "Trainer email is required";
      return errors;
    }
  };

  const handleSaveSubmit = async () => {
    let errors = validate(formData);
    if (errors?.name) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setIsSaving(true);
        const { name, email, role } = formData;
        await userApi.create({
          name,
          email: email.trim().toLowerCase(),
          role,
          gym_id: gym.id,
        });
        fetchGyms();
        fetchTrainers();
      } catch (e: any) {
        console.log(e);
        setAlert({
          display: true,
          type: "error",
          message: e.message || "An error has occurred",
        });
      } finally {
        setIsSaving(false);
        setShowModal(false);
        setAlert({
          display: true,
          type: "info",
          message: `${formData.name} was successfully invited to ${gym.name} as a ${formData.role}`,
        });
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className="py-4 pb-6">
        <div className="mb-4">
          <FloatingInput
            ref={inputRef}
            value={formData.name}
            onChange={handleChange}
            label="Name"
            name="name"
            error={formErrors.name}
          />
        </div>
        <div className="mb-4">
          <FloatingInput
            value={formData.email}
            onChange={handleChange}
            label="Email"
            name="email"
            error={formErrors.name}
          />
        </div>
        <div>
          <SelectInput
            name="Role"
            inputProps={{ className: "border-primary" }}
            options={[
              { label: "Trainer", value: "trainer" },
              { label: "Admin", value: "admin" },
              { label: "Owner", value: "owner" },
            ]}
            onChange={handleRoleChange}
          />
        </div>
        <div className="mt-4 -mb-4">
          <ModalButton
            isLoading={isSaving}
            onSubmit={handleSaveSubmit}
            label={"Invite"}
            loadingLabel="Saving"
            icon={faPaperPlane}
          />
        </div>
      </div>
    </>
  );
};

export default AddUserModal;
